'use client';

import { LocalOverrideAdapter } from '@statsig/js-local-overrides';
import {
  type StatsigOptions,
  StatsigProvider,
  type StatsigUser,
  useClientBootstrapInit,
} from '@statsig/react-bindings';
import { env } from 'next-runtime-env';
import { PropsWithChildren } from 'react';

interface StatsigClientProviderProps extends PropsWithChildren {
  readonly user: StatsigUser;
  readonly data?: string;
  readonly optionOverrides?: Partial<StatsigOptions>;
}

const getStatsigConfigEnv = (env: string) => {
  if (env === 'production') {
    return {
      disableLogging: true,
    };
  }

  return {
    // Allows setting local overrides for non-prod envs
    overrideAdapter: new LocalOverrideAdapter(),
  };
};

export default function StatsigProviderWrapper({
  user,
  data,
  children,
  optionOverrides,
}: StatsigClientProviderProps): JSX.Element {
  const NEXT_PUBLIC_STATSIG_CLIENT_KEY = env('NEXT_PUBLIC_STATSIG_CLIENT_KEY');
  const NEXT_PUBLIC_OURA_ENV = env('NEXT_PUBLIC_OURA_ENV');

  if (!NEXT_PUBLIC_STATSIG_CLIENT_KEY || !NEXT_PUBLIC_OURA_ENV) {
    throw new Error('Missing required env vars for Statsig initialization.');
  }

  const stableId = user.customIDs?.stableID;

  if (!stableId) {
    console.error('Missing stable id. Statsig will not bootstrap properly');
  }

  const options: StatsigOptions = {
    environment: { tier: `${NEXT_PUBLIC_OURA_ENV}` },
    networkConfig: {
      preventAllNetworkTraffic: process.env.NODE_ENV === 'test',
    },
    ...getStatsigConfigEnv(NEXT_PUBLIC_OURA_ENV),
    ...optionOverrides,
  };

  const client = useClientBootstrapInit(
    NEXT_PUBLIC_STATSIG_CLIENT_KEY,
    user,
    data ?? '{}',
    options,
  );

  return <StatsigProvider client={client}>{children}</StatsigProvider>;
}

/*A "slice" is a collection of Redux reducer logic and actions for a single feature in your app, 
typically defined together in a single file. The name comes from splitting up the root Redux state
 object into multiple "slices" of state. */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { type Order } from '@/types/Order';

import { RootState } from '../store';

export const initialState: Order = {
  agreesToShare: undefined,
  lineItems: [],
  shippingAddress: {
    fname: '',
    lname: '',
    address: '',
    city: '',
    country: '',
    state: '',
    postal: '',
  },
  netsuiteOrderId: '',
  netsuiteInternalId: '',
  orderComplete: false,
  orderDate: '',
  salesOrderId: '',
};

/* Reducers */
const orderSlice = createSlice({
  name: 'order',
  initialState,
  reducers: {
    initializeOrder() {},

    // we encourage naming reducers as past-tense "this happened" names like skuSelected,
    // because we are describing "an event that occurred in the application"
    updateLineItems: {
      reducer(state, action: PayloadAction<Order>) {
        state.lineItems = action.payload.lineItems;
      },
      prepare(lineItems: Order['lineItems']) {
        return {
          payload: { lineItems },
        };
      },
    },

    submittedSharing: {
      reducer(state, action: PayloadAction<Order>) {
        state.agreesToShare = action.payload.agreesToShare;
      },
      prepare(agreesToShare: Order['agreesToShare']) {
        return {
          payload: { agreesToShare },
        };
      },
    },

    orderAdded: {
      reducer(state, action: PayloadAction<Order>) {
        Object.assign(state, action.payload);
      },
      prepare(order: Order) {
        return { payload: { ...order } };
      },
    },

    resetOrder: {
      reducer(state, action: PayloadAction<Order>) {
        Object.assign(state, action.payload);
      },
      prepare() {
        return { payload: initialState };
      },
    },

    orderCompleted: {
      reducer(state) {
        state.orderComplete = true;
      },
      prepare(orderComplete: Order['orderComplete']) {
        return {
          payload: { orderComplete },
        };
      },
    },
  },
});

/* Actions */
export const {
  initializeOrder,
  submittedSharing,
  updateLineItems,
  orderAdded,
  resetOrder,
  orderCompleted,
} = orderSlice.actions;

/* Selectors */
export const selectOrder = (state: RootState) => state.order;

export default orderSlice.reducer;
